import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private _router: Router) { }

  canActivate(): boolean {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      // console.log("Failed to authenticate.");
      this._router.navigate(["/login"]);
      return false;
    }
  }
}
