<div class="row" style="padding-top: 50px; background:rgb(255,255,255) !important">
    <div class="container d-flex justify-content-center">
        <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
        <!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
        <!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
        <!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
        <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
        <!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
        <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
        <!-- <div class="container m-2 d-flex justify-content-center" > -->


        <!-- Human Resources -->
        <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let item of data">
            <div class="card">
                <div class="card-header bg-dark mpb">
                    {{item.moduleName}}
                </div>
                <div class="card-body mpb2">
                    <div class="card-text">
                        <img [src]="'assets/images/icons/'+ item.icon" alt="" width="100" height="100">
                        <a href="{{item.url}}" class="btn btn-outline-success ml-4"
                            (click)="loadMenu(item.moduleId, item.url)">
                            <button type="button" class="btn btn-danger">Enter</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Haulage and Logistics -->
        <!--- nth card <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="card">
                      <div class="card-header">
                          Sales
                      </div>
                      <div class="card-body">
                          <div class="card-text">
                              <a href="http://localhost:4207" target="_blank">
                                  <img src="assets/images/icons/place.png" alt="" width="120" height="120">
                                  <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                              </a>
                          </div>
                      </div>
                  </div>
              </div> --->


        <!-- </div> -->
    </div>
</div>