<style>
     :host {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        font-size: 14px;
        color: #fff;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 8px 0;
    }
    
    p {
        margin: 0;
    }
    
    .spacer {
        flex: 1;
    }
    
    .bg-light {
        background-color: #f8f9fa !important;
    }
    
    .btn-outline-success {
        color: #fff;
        background-color: orangered;
        border-color: red;
    }
    
    .btn-outline-success:hover {
        color: #fff;
        background-color: yellow;
        border-color: #fff;
    }
    
    .toolbar {
        height: 60px;
        margin: 8px;
        display: flex;
        align-items: center;
        color: white;
        font-weight: 600;
    }
    
    .toolbar img {
        margin: 0 16px;
    }
    
    .toolbar #twitter-logo {
        height: 40px;
        margin: 0 30px;
    }
    
    .toolbar #twitter-logo:hover {
        opacity: 0.8;
    }
    
    .card {
        margin: 0 auto;
        /* Added */
        float: none;
        /* Added */
        margin-bottom: 10px;
        /* Added */
        border-radius: 5px;
        border: 1px solid rgba(0, 0, 0, .125);
    }
    
    .ml-4,
    .mx-4 {
        margin-left: 1.5rem !important;
    }
    
    .card .card-header {
        position: relative;
        display: flex;
        flex-direction: row;
        text-transform: uppercase;
        background: transparent;
        color: #000;
        font-size: 13px;
        letter-spacing: 0.02em;
        align-items: center;
        -webkit-box-align: center;
        border-radius: 30px 30px 0 0;
        font-family: "Montserrat", Helvetica, sans-serif;
    }
    
    .card-header {
        padding: .75rem 1.25rem;
        margin-bottom: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .125);
        background-color: orangered;
    }
    
    .content {
        display: flex;
        margin: 32px auto;
        padding: 0 16px;
        max-width: 960px;
        flex-direction: column;
        align-items: center;
    }
    
    svg.material-icons {
        height: 24px;
        width: auto;
    }
    
    svg.material-icons:not(:last-child) {
        margin-right: 8px;
    }
    
    svg#rocket {
        width: 80px;
        position: absolute;
        left: -10px;
        top: -24px;
    }
    
    svg#rocket-smoke {
        height: 100vh;
        position: absolute;
        top: 10px;
        right: 180px;
        z-index: -10;
    }
    
    a,
    a:visited,
    a:hover {
        color: #1976d2;
        text-decoration: none;
    }
    
    a:hover {
        color: #125699;
    }
    
    footer {
        background-color: orangered;
        margin-top: 8px;
        display: flex;
        align-items: center;
        line-height: 20px;
        bottom: 100%;
    }
    
    footer a {
        display: flex;
        align-items: center;
    }
</style>

<!-- Toolbar -->
<div style="background: #fff !important">
    <div class="container">
        <div class="toolbar bg-light" role="banner">

            <img width="150" alt="Angular Logo" src="assets/images/banexgroup.png" />

            <div class="spacer"></div>
            <button style="margin-right: 10px;" class="btn btn-sm btn-danger signout" > Sign Out</button>

        </div>
    </div>


    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
    <!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
    <!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
    <div class="container mt-5 d-flex justify-content-center" style="margin-top: 5%;">
        <div class="row">

            <!-- Human Resources -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header bg-danger">
                        Human Resource
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/HumanResources.png" alt="" width="120" height="120">
                            <a href="http://localhost:4201" target="_blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Legal Management -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header bg-danger">
                        Legal Management
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/LegalManagement.png" alt="" width="120" height="120">
                            <a routerLink="/account/receipt" class="btn btn-outline-success ml-4" target="_blank">
                            Enter
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Audit Trail C:\Users\user\Documents\Banex Estate\src\assets\images\icons -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Audit Trail
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/icons/AuditTrail.png" alt="" width="120" height="120">
                            <a href="http://localhost:4202" target="blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Properties -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Properties
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/property.png" alt="" width="120" height="120">
                            <a href="http://localhost:4203" target="_blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Rent -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Rent
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/Rental.png" alt="" width="120" height="120">
                            <a href="http://localhost:4204" target="_blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tenant -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Tenant
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/Tenant.png" alt="" width="120" height="120">
                            <a href="http://localhost:4205" target="_blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Report -->
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Report
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <img src="assets/images/Icons/Report.png" alt="" width="120" height="120">
                            <a href="http://localhost:4206" target="_blank">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Haulage and Logistics -->
            <!--- nth card <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Sales
                    </div>
                    <div class="card-body">
                        <div class="card-text">
                            <a href="http://localhost:4207" target="_blank">
                                <img src="assets/images/icons/place.png" alt="" width="120" height="120">
                                <button type="button" class="btn btn-outline-success ml-4">Enter</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div> --->

        </div>
    </div>

    <div class="card-footer text-center mt-5 pt-5" style="margin-top: 100px;">
        <a href="javascript:;" class="mr-3" style="text-decoration: none;">
            <span>Copyright &copy; 2019</span> <span style="color:gold;">Banex Group</span>. All rights reserved.
        </a>
        <a href="https://www.sundimension.ng/" target="_blank" style="text-decoration: none;">
            <span style="color: indigo;
                  "> Powered By Sundimension</span>
        </a>
    </div>
</div>