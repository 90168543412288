import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker';

const materialComponents = [MatDatepickerModule, MatNativeDateModule, MatInputModule, MatDialogModule];

@NgModule({
  declarations: [],
  imports: [
    CommonModule, materialComponents
  ],
  exports: [
    materialComponents
  ]
})
export class MaterialModule { }
