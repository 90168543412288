<div class="row">
  <div class="container">
    <!-- this *ngFor="let item of data" will produce what's im the DB -->
    <div class="col-lg-4 col-md-3 col-sm-6" *ngFor="let item of data">
      <div class="card mb-3 rounded-lg">
        <div class="row no-gutters">
          <!-- <div class="col-lg col-md-4 ">
                    <img src="assets/images/pdf.png" class="card-img" alt="pdf image" >
                  </div> -->
          <div class="col-md-12">
            <div class="card-body">
              <img
                src="../../assets/images/policy.png"
                class="card-img"
                alt="pdf image"
                height="60px"
              />
              <h4 class="card-title">{{ item.PolicyName }}</h4>
              <p class="card-text">{{ item.Description }}</p>
              <a
                href="{{ serverUrl + item.PolicyUrl }}"
                class="btn blue"
                target="_blank"
                >{{ action }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
