import { API_URL, BASE_URL } from "./../services/app-config";
import { Component, OnInit } from "@angular/core";
import { NgModule } from "@angular/core";
import { policyUrl } from "./policy-url";
import { HttpService } from "src/app/services/http.service";
import swal from "sweetalert2";

@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styleUrls: ["./policy.component.css"],
})
export class PolicyComponent implements OnInit {
  obj = {
    PolicyID: null,
    PolicyName: null,
    PolicyUrl: null,
    Description: null,
  };
  downLoadFile: any;
  data: [];
  serverUrl = API_URL + "upload/";
  action = "View";

  constructor(protected http: HttpService) {}

  ngOnInit() {
    // swal.showLoading();
    this.http.get(policyUrl.policydocument.list).subscribe(
      (success) => {
        this.data = success.data;
        // swal.close();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  DownloadFile() {
    return this.http
      .get("http://localhost/sdhumanresource.api/upload/")
      .subscribe((res) => {
        return {
          filename: this.obj.PolicyName,
        };
      });
  }
}
