import { UploadService } from './services/upload.service';
import { MaterialModule } from './material/material.module';
import { PolicyComponent } from './policy/policy.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MAT_STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { DxButtonModule } from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { GenericService } from './services/generic.service';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DtService } from './services/dt.service';
import { LockscreenComponent } from './components/lockscreen/lockscreen.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { Error404Component } from './components/error404/error404.component';
import { Error505Component } from './components/error505/error505.component';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { PdfService } from './services/pdf-service.service';
import { MySharedEvent } from './services/shared-events.service';

@NgModule({
  declarations: [
    AppComponent,
    DashboardLayoutComponent,
    SimpleLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    AuthLayoutComponent,
    DashboardComponent,
    LockscreenComponent,
    ForgotPasswordComponent,
    Error404Component,
    Error505Component,
    PolicyComponent,
    BlankLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatStepperModule,
    NgSelectModule,
    DxButtonModule,
    MaterialModule,
    MatFormFieldModule,
    MatInputModule
    // UploadService
  ],
  providers: [
    {
      provide: CdkStepperModule,
      useValue: { /* displayDefaultIndicatorType: true, */ showError: false },
    },
    GenericService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    // AuthService, AuthGuard, DtService, CalenderService
    AuthService,
    AuthGuard,
    DtService, PdfService, MySharedEvent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
