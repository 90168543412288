<footer class="footer card-footer pt-5 mt-5 ml-2 navbar-border navbar-shadow foot"
  style="background-color: rgb(139, 145, 219);">
  <h4>
    Copyright&copy; 2020
    <a href="javascript:;" class="mr-3" style="text-decoration: none;">
      <span style="color: rgb(255, 255, 255);">Banex Enterprise Resource Planning Software</span>. </a> <span
      style="font-weight: bolder;">Powered By</span>
    <a href="https://www.sundimension.com.ng/" target="_blank" style="text-decoration: none; font-weight: bolder;">
      <span> Sundimension</span>
    </a>
  </h4>
</footer>