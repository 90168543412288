import { Error404Component } from './components/error404/error404.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { BlankLayoutComponent } from './layouts/blank-layout/blank-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './services/auth.guard';
// import { PreviewLayoutComponent } from './layouts/preview-layout/preview-layout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PolicyComponent } from './policy/policy.component';

const routes: Routes = [
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      { path: 'login', component: LoginComponent },
    ],
  },
  {
    // path: "", component: DashboardLayoutComponent, canActivate: [AuthGuard],
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: '', component: DashboardComponent },
      // { path: "menu", loadChildren: "./menu/menu.module#MenuModule" },
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule',
      },
      {
        path: 'applicant',
        loadChildren: './applicant/applicant.module#ApplicantModule',
      },
      {
        path: 'correspondence',
        loadChildren:
          './correspondence/correspondence.module#CorrespondenceModule',
      },
      {
        path: 'employee',
        loadChildren: './employee/employee.module#EmployeeModule',
      },
      { path: 'grade', loadChildren: './grade/grade.module#GradeModule' },
      { path: 'leave', loadChildren: './leave/leave.module#LeaveModule' },
      { path: 'legal', loadChildren: './legal/legal.module#LegalModule' },
      {
        path: 'offence',
        loadChildren: './offence/offence.module#OffenceModule',
      },
      {
        path: 'payment',
        loadChildren: './payment/payment.module#PaymentModule',
      },
      {
        path: 'charts',
        loadChildren: './charts/charts.module#ChartsModule',
      },
      {
        path: 'payroll',
        loadChildren: './payroll/payroll.module#PayrollModule',
      },
      {
        path: 'privileges',
        loadChildren: './privileges/privileges.module#PrivilegesModule',
      },
      {
        path: 'product',
        loadChildren: './product/product.module#ProductModule',
      },
      {
        path: 'property',
        loadChildren: './property/property.module#PropertyModule',
      },
      { path: 'rent', loadChildren: './rent/rent.module#RentModule' },
      { path: 'salary', loadChildren: './salary/salary.module#SalaryModule' },
      {
        path: 'settings',
        loadChildren: './settings/settings.module#SettingsModule',
      },
      { path: 'suite', loadChildren: './suite/suite.module#SuiteModule' },
      { path: 'task', loadChildren: './task/task.module#TaskModule' },
      { path: 'tenant', loadChildren: './tenant/tenant.module#TenantModule' },
      { path: 'vendor', loadChildren: './vendor/vendor.module#VendorModule' },

      {
        path: 'approval',
        loadChildren: './approval/approval.module#ApprovalModule',
      },
    ],
  },
  {
    path: 'policy',
    component: DashboardLayoutComponent,
    children: [{ path: 'policy', component: PolicyComponent }],
  },

  {
    path: '',
    component: BlankLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      // { path: "dashboard", component: Error404Component },
      { path: 'dashboard', component: DashboardComponent },
    ],
  },
  // {
  //   path: "", component: PreviewLayoutComponent,
  //   children: [
  //     { path: "preview", loadChildren: "./previews/previews.module#PreviewsModule" },
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
