<div class="app">
  <app-header></app-header>
  <!-- aside -->
  <aside id="aside" class="app-aside modal fade" role="menu">
    <div class="left">
      <div class="box bg-white">
        <div
          class="navbar md-whiteframe-z1 no-radius blue"
          style="background-color: rgb(139, 145, 219);"
        >
          <!-- brand -->
          <a class="navbar-brand">
            <img
              src="/assets/images/banex-logo.png"
              alt="."
              style="max-height: 90px;"
            />
            <!-- <span class="hidden-folded m-l inline">Company Name</span> -->
            <!-- <small>Passport Management System</small> -->
          </a>
          <!-- / brand -->
        </div>
        <div class="box-row">
          <div class="box-cell scrollable hover">
            <div class="box-inner">
              <div class="p hidden-folded">
                <!-- <div class="rounded w-64 bg-white inline pos-rlt">
                <img src="images/a0.jpg" class="img-responsive rounded">
              </div> -->
                <a
                  class="block m-t-sm"
                  ui-toggle-class="hide, show"
                  target="#nav, #account"
                >
                  <span class="block font-bold"><h4>{{user_name}}</h4></span>
                  <!-- <span class="block font-bold"> <h4>CornelPrime</h4> </span> -->
                  <span class="pull-right auto">
                    <i class="fa inline fa-caret-down"></i>
                    <i class="fa none fa-caret-up"></i>
                  </span>
                  {{email}}
                  <!-- collinscornelius9@gmail.com -->
                </a>
              </div>
              <div id="nav">
                <nav ui-nav>
                  <ul class="nav">
                    <ng-container *ngFor="let item of links">
                      <!-- HEADER -->
                      <ng-container *ngIf="item.header">
                        <li class="nav-header m-v-sm hidden-folded">
                          Menu Navigation
                        </li>
                      </ng-container>

                      <!-- LINK -->
                      <ng-container *ngIf="item.sublinks.length == 0">
                        <li [class.active]="item.link === currentUrl">
                          <a md-ink-ripple [routerLink]="item.link">
                            <!-- <a md-ink-ripple *ngIf="item.external==0" [routerLink]="item.link"> -->
                            <i
                              class="icon mdi-action-settings-input-svideo i-20"
                            ></i>
                            <span>{{ item.title }}</span>
                          </a>

                          <a
                            md-ink-ripple
                            *ngIf="item.external == 1"
                            [href]="item.link"
                            [target]="item.target"
                          >
                            <i
                              class="icon mdi-action-settings-input-svideo i-20"
                            ></i>
                            <span>{{ item.title }}</span>
                          </a>
                        </li>
                      </ng-container>

                      <!-- LINK WITH SUBLINKS -->
                      <ng-container *ngIf="item.sublinks.length > 0">
                        <li>
                          <a md-ink-ripple href>
                            <span class="pull-right text-muted">
                              <i class="fa fa-caret-down"></i>
                            </span>
                            <i class="icon mdi-content-sort i-20"></i>
                            <span>{{ item.title }}</span>
                          </a>
                          <ul class="nav nav-sub">
                            <ng-container *ngFor="let subitem of item.sublinks">
                              <li
                                [class.active]="subitem.link === currentUrl"
                                *ngIf="subitem.sublinks.length == 0"
                              >
                                <a
                                  md-ink-ripple
                                  href
                                  [routerLink]="subitem.link"
                                  >{{ subitem.title }}</a
                                >

                                <a
                                  md-ink-ripple
                                  href
                                  *ngIf="subitem.external == 1"
                                  [href]="subitem.link"
                                  [target]="subitem.target"
                                  >{{ subitem.title }}</a
                                >
                              </li>
                              <!-- for the second dropdown -->
                              <li *ngIf="subitem.sublinks.length > 0">
                                <a md-ink-ripple href>
                                  <span class="pull-right text-muted">
                                    <i class="fa fa-caret-down"></i>
                                  </span>
                                  <span class="font-normal">{{
                                    subitem.title
                                  }}</span>
                                </a>
                                <ul class="nav nav-sub">
                                  <ng-container
                                    *ngFor="let subitem2 of subitem.sublinks"
                                  >
                                    <li
                                      [class.active]="
                                        subitem2.link === currentUrl
                                      "
                                    >
                                      <a
                                        md-ink-ripple
                                        href
                                        [routerLink]="subitem2.link"
                                        >{{ subitem2.title }}</a
                                      >
                                      <!-- <a md-ink-ripple href *ngIf="subitem2.external==0" [routerLink]="subitem2.link">{{subitem2.title}}</a> -->

                                      <a
                                        md-ink-ripple
                                        href
                                        *ngIf="subitem2.external == 1"
                                        [href]="subitem2.link"
                                        [target]="subitem2.target"
                                        >{{ subitem2.title }}</a
                                      >
                                    </li>
                                  </ng-container>
                                </ul>
                              </li>
                            </ng-container>
                          </ul>
                        </li>
                      </ng-container>
                    </ng-container>

                    <!-- <li class="b-b b m-v-sm"></li>
                  <li>
                    <a md-ink-ripple ui-toggle-class="hide, show" target="#nav, #account">
                      <span>Settings</span>
                    </a>
                  </li>
                  <li>
                    <a md-ink-ripple href="page.document.html">
                      <span>Documentation</span>
                    </a>
                  </li> -->
                  </ul>
                </nav>
              </div>
              <div id="account" class="hide m-v-xs">
                <nav>
                  <ul class="nav">
                    <li>
                      <a md-ink-ripple href="page.profile.html">
                        <i class="icon mdi-action-perm-contact-cal i-20"></i>
                        <span>My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a md-ink-ripple href="page.settings.html">
                        <i class="icon mdi-action-settings i-20"></i>
                        <span>Settings</span>
                      </a>
                    </li>
                    <li>
                      <a md-ink-ripple (click)="_auth.logoutUser()">
                        <i class="icon mdi-action-exit-to-app i-20"></i>
                        <span>Logout</span>
                      </a>
                    </li>
                    <li class="m-v-sm b-b b"></li>
                    <!-- <li>
                    <div class="nav-item" ui-toggle-class="folded" target="#aside">
                      <label class="md-check">
                        <input type="checkbox">
                        <i class="purple no-icon"></i>
                        <span class="hidden-folded">Folded aside</span>
                      </label>
                    </div>
                  </li> -->
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <nav>
          <ul class="nav b-t b">
            <li>
              <a
                href="http://themeforest.net/item/materil-responsive-admin-dashboard-template/11062969"
                target="_blank"
                md-ink-ripple
              >
                <i class="icon mdi-action-help i-20"></i>
                <span>Help &amp; Feedback</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </aside>
  <!-- / aside -->

  <!-- content -->
  <div
    id="content"
    class="app-content d-flex justify-content-center"
    role="main"
  >
    <div class="box boxy">
      <div class="box-row">
        <div class="box-cell">
          <div class="box-inner">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- / content -->
</div>
