import { Component } from '@angular/core';
//import { CalenderService } from './services/fullcalendar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'epress';
  // @ViewChild("calendar") table: ElementRef;
  // // calender: any;
  // public constructor(protected fullCalender: CalenderService){
   
  // }

  // ngOnInit()
  // {
  //   this.calender = this.fullCalender.default(this.table);
  // }
}
