import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { AuthService } from "../../services/auth.service";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
  SafeStyle,
} from "@angular/platform-browser";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  obj = { username: "", password: "" };
  constructor(
    public _auth: AuthService,
    private _router: Router,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit() { }

  loginUser() {
    // console.log(this.obj);
    //this._router.navigate(["dashboard"]);
    this._auth.loginUser(this.obj).subscribe(
      (res) => {
        // console.log(res);
        localStorage.setItem("token", res.token);
        localStorage.setItem(
          "userinfo",
          res.data.firstName + " " + res.data.lastName
        );
        localStorage.setItem("email", res.data.email);
        /* if (this._router.navigate([""])) {
          window.location.reload();
        } */

        this._router.navigate(["/dashboard"]);
      },
      (err) => {
        console.log(err);
        swal.fire({
          type: "error",
          title: "Oops...",
          text: err.error.message || "Something went wrong!",
        });
      }
    );
  }
}
