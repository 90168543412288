<div class="app">
    <app-header></app-header>
  
    <!-- content -->
    <div
      id="content"
      class="app-content d-flex justify-content-center"
      role="main"
    >
      <div class="box boxy">
        <div class="box-row">
          <div class="box-cell">
            <div class="box-inner">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
    <!-- / content -->
  </div>
  