import { Component, OnInit, OnDestroy } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { MySharedEvent } from 'src/app/services/shared-events.service';

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
})
// export class DashboardLayoutComponent implements OnInit, OnDestroy {
export class DashboardLayoutComponent implements OnInit {
  public links = [];
  public data = [];
  public modules = [];
  public user_name = null;
  public email = null;
  public currentUrl = "";
  public menu_title = "MAIN NAVIGATION";
  moduleId = '-1';
  timeout: any;

  constructor(public _auth: AuthService, private router: Router, private events: MySharedEvent) {
    this.menu_title = "MAIN NAVIGATION";
    this.router.events.subscribe((evt: any) => (this.currentUrl = evt.url));
  }

  // ngOnInit() {
  //   document.body.className = "vertical-layout vertical-menu-modern 2-columns fixed-navbar menu-expanded"
  // }

  ngOnInit() {
    this.user_name = this._auth.getUserInfo();
    this.email = this._auth.getUserEmail();
    this.moduleId = localStorage.getItem('moduleId');
    this.events.eventData.subscribe((evt: any) => {
      // console.log(evt);
      const data = this.data.filter(item => item.moduleId === evt + '' || item.moduleId === 0);

      this.links = this.data.filter(item => item.moduleId === evt + '');
      this.moduleId = evt;
      this.menu_title = this.modules.find(item => item.moduleId === evt + '').moduleName;
    });

    this.user_name = this._auth.getUserInfo();

    this.modules = JSON.parse(localStorage.getItem('modules'));
    this.data = JSON.parse(localStorage.getItem('menus'));
    const tree = this.data;
    // this.data = res.data.menus;
    if (this.data) {

      this.links = this.GenerateTree(tree.filter(item => item.moduleId === this.moduleId + '' || item.moduleId === '0'));
      this.menu_title = this.modules.find(item => item.moduleId === this.moduleId + '').moduleName;
    }
    // this._auth.menu().subscribe(
    //   (res) => {
    //     // console.log(JSON.stringify(res.data));
    //     this.links = this.GenerateTree(res.data);
    //     // console.log(this.links);
    //     // console.log(res.data);
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

  // ngOnDestroy() {
  //   document.body.className ="";
  // }

  buildTree(tree, item) {
    if (item) {
      // if item then have parent
      for (var i = 0; i < tree.length; i++) {
        // parses the entire tree in order to find the parent
        if (String(tree[i].id) === String(item.parent)) {
          // bingo!
          tree[i].sublinks.push(item); // add the child to his parent
          break;
        } else this.buildTree(tree[i].sublinks, item); // if item doesn't match but tree have sublinks then parses sublinks again to find item parent
      }
    } else {
      // if no item then is a root item, multiple root items are supported
      var idx = 0;
      while (idx < tree.length)
        if (tree[idx].parent && tree[idx].parent > 0)
          this.buildTree(tree, tree.splice(idx, 1)[0]);
        // if have parent then remove it from the array to relocate it to the right place
        else idx++; // if doesn't have parent then is root and move it to the next object
    }
  }

  GenerateTree(data) {
    for (var i = 0; i < data.length; i++) {
      // add sublinks to every item
      data[i].sublinks = [];
    }
    this.buildTree(data, null);
    data.sort((a, b) => a.order_by - b.order_by);
    return data;
  }
}
