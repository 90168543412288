<!-- Content Navbar -->
<div class="navbar md-whiteframe-z1 no-radius blue" style="background-color: rgb(139, 145, 219);">
  <!-- Open side - Naviation on mobile -->
  <a md-ink-ripple data-toggle="modal" data-target="#aside" class="navbar-item pull-left visible-xs visible-sm"><i
      class="mdi-navigation-menu i-24"></i></a>
  <!-- / -->
  <!-- Page title - Bind to $state's title -->
  <!-- <div class="navbar-item pull-left h4" style="margin-left: 30px;">Real Estate</div> -->
  <!-- / -->
  <!-- Common tools -->
  <ul class="nav nav-sm navbar-tool pull-right">
    <li>
      <button style="color: rgb(38, 0, 255); margin: 13px;">
        <a md-ink-ripple ui-toggle-class="show" routerLink="/dashboard" class="btn btn-outline-success ml-4">
          <i>Dashboard</i>
        </a>
        <!-- <img src="assets/images/pdf.png" /> -->
      </button>
    </li>
    <li>
      <button style="color: rgb(38, 0, 255); margin: 13px;">
        <a md-ink-ripple ui-toggle-class="show" routerLink="/policy/policy" class="btn btn-outline-success ml-4">
          <i>Company's Policy</i>
        </a>
        <!-- <img src="assets/images/pdf.png" /> -->
      </button>
    </li>

    <!-- <li><app-policy></app-policy></li>-->
    <li>
      <a md-ink-ripple ui-toggle-class="show" target="#search">
        <i class="mdi-action-search i-24"></i>
      </a>
    </li>
    <!-- <li>
            <a md-ink-ripple data-toggle="modal" data-target="#user">
                <i class="mdi-social-person-outline i-24"></i>
            </a>
        </li> -->
    <!-- <li class="dropdown">
            <a md-ink-ripple data-toggle="dropdown">
                <i class="mdi-navigation-more-vert i-24"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-scale pull-right pull-up text-color">
                <li><a href>Single-column view</a></li>
                <li><a href>Sort by date</a></li>
                <li><a href>Sort by name</a></li>
                <li class="divider"></li>
                <li><a href>Help &amp; feedback</a></li>
            </ul>
        </li> -->
  </ul>
  <div class="pull-right" ui-view="navbar@"></div>
  <!-- / -->
  <!-- Search form -->
  <div id="search" class="pos-abt w-full h-full blue hide">
    <div class="box">
      <div class="box-col w-56 text-center">
        <!-- hide search form -->
        <a md-ink-ripple class="navbar-item inline" ui-toggle-class="show" target="#search"><i
            class="mdi-navigation-arrow-back i-24"></i></a>
      </div>
      <div class="box-col v-m">
        <!-- bind to app.search.content -->
        <input class="form-control input-lg no-bg no-border" placeholder="Search" ng-model="app.search.content" />
      </div>
      <div class="box-col w-56 text-center">
        <a md-ink-ripple class="navbar-item inline"><i class="mdi-av-mic i-24"></i></a>
      </div>
    </div>
  </div>
  <!-- / -->
</div>
<!-- Content -->