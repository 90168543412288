<div class="flexbox-container" style="top: 0px !important; position: relative;">
  <div class="row" style="height: 50vh; padding: 2rem;">
    <div class="col-12">
      <div class="col-lg-4 col-sm-4 col-md-4">
        <div class="card lest" style="background-color: rgba(233, 227, 227, 0.479) !important;">
          <div class="card-header border-0">
            <div class="card-title text-center">
              <img src="./assets/images/banex-logo.png" alt="branding logo" width="70px" />
              <!-- <img
                src="./assets/images/SD-LOGO.png"
                alt="branding logo"
                width="70px"
              /> -->
              <br /><br />
              <h2 class="text-dark" style="font-weight: bolder;">Banex ERP Software</h2>
            </div>
          </div>
          <div class="card-content" style="background-color: rgba(233, 227, 227, 0.329);">
            <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
              <span style="background-color: rgba(233, 227, 227, 0.555);font-weight: bolder; font-size: large;">Login
                Using Account
                Details</span>
            </p>
            <div class="card-body">
              <form #form="ngForm" (submit)="loginUser()">
                <fieldset class="form-group position-relative has-icon-left">
                  <input type="text" [(ngModel)]="obj.username" name="username" class="form-control" id="user-name"
                    placeholder="Your Username" required />
                  <!-- <div class="form-control-position">
                                      <i class="fa fa-user"></i>
                                  </div> -->
                </fieldset>
                <fieldset class="form-group">
                  <input type="password" class="form-control" id="user-password" placeholder="Enter Password" required
                    [(ngModel)]="obj.password" name="password" required />
                  <!-- <div class="form-control-position">
                                      <i class="fa fa-key"></i>
                                  </div>  -->
                </fieldset>

                <button type="submit" [disabled]="form.invalid" class="btn btn-outline-info btn-block"
                  style="font-weight: bolder; font-size: large;">
                  <i class="fa fa-unlock"></i> Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div style="top: 70vh; padding-top: 0; position: absolute;">

    <a href="./../../../assets/ABCD.pdf" target="_blank" style="left: 10vw;" class="btn btn-primary">USER'S MANUAL</a>

  </div>
  <!-- <div class="center-block w-xxl w-auto-xs p-v-md backg">
        <div class="navbar">
            <div class="navbar-brand m-t-lg text-center">
                <div class="logoimage">
                  <img src="../../../assets/images/SD-LOGO.png" alt="branding logo">
                    <a href="www.banex.com"><img class="banexgroup" src="../../../assets/images/Banex_Logo.png" alt="logo" style="max-height: 200px;"></a>
                    <div>
                        <div class="lead">Welcome To<br /> <span>Banex Enterprise Resource Planning Software</span></div>
                    </div>
                </div>

            </div>
        </div>

        <div class="p-lg panel md-whiteframe-z1 text-color m" id="formwd">
            <div class="m-b text-lg-center">
                <h5><i class="fa fa-user"> USER SIGN IN </i></h5>
            </div>
            <form #form="ngForm" (submit)="loginUser()" name="form">
                <div class="md-form-group float-label">
                    <label><b>Username</b></label>
                    <div class="">
                        <input type="text" [(ngModel)]="obj.username" name="username" class="md-input" id="user-name" placeholder="username" required>
                    </div>

                </div>
                <div class="md-form-group float-label">
                    <label><b>Password</b></label>
                    <div class="input-group-prepend">
                        <input type="password" class="md-input form-control" id="user-password" required [(ngModel)]="obj.password" name="password" placeholder="password" required>
                    </div>


                </div>
                <div class="m-b-md">
                    <label class="md-check">
                        <input type="checkbox"><i class="indigo"></i> Keep me signed in
                    </label>
                </div>
                <div class="text-center"><button md-ink-ripple type="submit" [disabled]="form.invalid" class="md-btn md-raised pink pull-center  btn-lg p-h-md"> <i class="fa fa-paper-plane-o fa-lg"> SIGN IN</i></button></div>

            </form>
        </div>



    </div> -->
  <!-- //css:foot -->
  <!-- <div class="card-footer foot">

        <footer>
            Copyright&copy; 2020
            <a href="javascript:;" class="mr-3" style="text-decoration: none;">
                <span style="color:gold;">Banex Enterprise Resource Planning Software</span>.
            </a> Powered By
            <a href="https://www.sundimension.com.ng/" target="_blank" style="text-decoration: none;">
                <span style="color: indigo;"> SunDimension</span>
            </a>
        </footer>
    </div> -->

  <div style=" position: absolute; bottom: 5vh; display: inline-block;
            background-color: rgba(0, 0, 0, 0.568);
            padding: 2px;
          ">
    <span style="color: rgb(255, 255, 255); left: 20vw;">Copyright &copy; 2020. All rights reserved.&nbsp;
    </span>
    <a href="https://www.sundimension.ng/" target="_blank" style="
              text-decoration: none;
              background-color: rgba(0, 0, 0, 0.747);
            ">
      <span style="color: rgb(59, 199, 16);">
        Powered By Sundimension</span>
    </a>
  </div>
</div>