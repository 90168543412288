import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.css']
})
export class BlankLayoutComponent implements OnInit {

  data = [];
  menu_title = '';
  constructor(public _auth: AuthService, private router: Router) {
    this.menu_title = 'MAIN NAVIGATION';
  }

  ngOnInit() {
    // this.data = JSON.parse(localStorage.getItem('modules'));
    // if (this.data == undefined) {
      this._auth.menu().subscribe(
        res => {
          this.data = res.data.modules;
          localStorage.setItem('menus', JSON.stringify(res.data.menus));
          localStorage.setItem('modules', JSON.stringify(res.data.modules));
        },
        err => {
          console.log(err);
        }
      );
    //}

  }
}
