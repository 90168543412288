import { tenantUrl } from './../../tenant/tenant-url';
import { suiteUrl } from './../../suite/suite-url';
import { settingsUrl } from './../../settings/settings-url';
import { propertyUrl } from './../../property/property-url';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpService } from './../../services/http.service';
import { employeeUrl } from './../../employee/employee-url';
import { Component, OnInit } from '@angular/core';
import { MySharedEvent } from 'src/app/services/shared-events.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  obj = {};
  data = [];
  constructor(
    protected http: HttpService,
    public router: Router,
    public actRoute: ActivatedRoute,
    private events: MySharedEvent) { }

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('modules'));
  }

  loadMenu(id, cs) {
    console.log(id);
    localStorage.setItem('moduleId', id);
    this.events.eventData.emit(id);
    this.router.navigate([cs]);
  }

}
