import { Injectable } from '@angular/core';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
//import { jsPDF } from 'jspdf';
//import 'jspdf-autotable';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  // exportGrid(type, componentGrid ,title, orientation, fileName) {
  //   const doc = new jsPDF(orientation);
  //   doc.addImage("/assets/img/logo-dark2.png", "PNG", 20, 10, 60, 20);
  //   doc.setFontSize(16);
  //   doc.text(title, 85, 25);
  //   //doc.autoPrint();
  //   exportDataGridToPdf({
  //     jsPDFDocument: doc,
  //     component: componentGrid,
  //     autoTableOptions: { startY: 30, headStyles: { fillColor: [242, 55, 56], textColor: 255, fontStyle: 'bold' } },
  //   }).then(() => {
  //     if(type===1)
  //       doc.save(fileName);
  //     else
  //     {
  //       doc.autoPrint();
  //       window.open(doc.output("bloburl").toString());
  //     }
  //     //const blob = ;
  //   })
  // }
}
