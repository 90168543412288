import { API_URL } from '../services/app-config';
export let policyUrl = {
	policydocument: { 
		add: API_URL + 'policydocument/add',
		update: API_URL + 'policydocument/update',
		list: API_URL + 'policydocument/list',
		get: API_URL + 'policydocument/get',
    }

}
