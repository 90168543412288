<div class="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true">
  <div class="main-menu-content ps-container ps-theme-light ps-active-y" data-ps-id="6857e179-f216-38fe-f615-d680acd83b81">
    <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <ng-container *ngIf=" menu_title !== '' ">
        <li class="navigation-header">
          <span data-i18n="nav.category.pages">{{menu_title}}</span><i class="la la-ellipsis-h ft-minus" data-toggle="tooltip"
            data-placement="right" data-original-title="Pages"></i>
        </li>
      </ng-container>

      <ng-container *ngFor="let item of links ">
          <!-- HEADER -->
          <ng-container *ngIf="item.header">
            <li class="header">{{item.header}}</li>
          </ng-container>
          <!-- COMPONENT -->

          <!-- LINK -->
          <ng-container *ngIf="item.sublinks.length==0">
            <li class="nav-item" [class.active]="item.link === currentUrl">
              <a  class="menu-item" *ngIf="item.external==0" [routerLink]="item.link">
                <i class="la la-{{item.icon}}"></i>
                <span class="menu-title">{{item.title}}</span>
              </a>
              <a  class="menu-item" *ngIf="item.external==1" [href]="item.link" [target]="item.target">
                <i class="la la-{{item.icon}}"></i>
                <span class="menu-title">{{item.title}}</span>
              </a>
            </li>
          </ng-container>
          <!-- LINK WITH SUBLINKS -->
          <ng-container *ngIf="item.sublinks.length>0">
            <li class="nav-item has-sub">
              <a>
                <i *ngIf="item.icon" class="la la-{{item.icon}}"></i>
                <span class="menu-title">{{item.title}}</span>
                <!-- <span class="pull-right-container">
                  <i class="la la-angle-left pull-right"></i>
                </span> -->
                <!--i class="la la-angle-left pull-right"></i-->
              </a>
              <ul class="menu-content">
                <ng-container *ngFor="let subitem of item.sublinks">
                  <li [class.active]="subitem.link === currentUrl" *ngIf="subitem.sublinks.length==0">
                    <a class="menu-item" *ngIf="subitem.external==0" [routerLink]="subitem.link">
                      <i *ngIf="subitem.icon" class="la la-{{subitem.icon}}"></i>
                      <span>{{subitem.title}}</span>
                    </a>
                    <a class="menu-item" *ngIf="subitem.external==1" [href]="subitem.link" [target]="subitem.target">
                      <i *ngIf="subitem.icon" class="la la-{{subitem.icon}}"></i>
                      <span>{{subitem.title}}</span>
                    </a>
                  </li>
                  <li class="has-sub" *ngIf="subitem.sublinks.length>0">
                    <a class="menu-item">
                      <i *ngIf="subitem.icon" class="la la-{{subitem.icon}}"></i>
                      <span>{{subitem.title}}</span>
                      <!-- <span class="pull-right-container">
                        <i class="la la-angle-left pull-right"></i>
                      </span> -->
                      <!--i class="la la-angle-left pull-right"></i-->
                    </a>
                    <ul class="menu-content">
                      <ng-container *ngFor="let subitem2 of subitem.sublinks">
                        <li [class.active]="subitem2.link === currentUrl">
                          <a class="menu-item" *ngIf="subitem2.external==0" [routerLink]="subitem2.link">
                            <i *ngIf="subitem2.icon" class="la la-{{subitem2.icon}}"></i>
                            <span>{{subitem2.title}}</span>
                          </a>
                          <a  class="menu-item" *ngIf="subitem2.external==1" [href]="subitem2.link" [target]="subitem2.target">
                            <i *ngIf="subitem2.icon" class="la la-{{subitem2.icon}}"></i>
                            <span>{{subitem2.title}}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ng-container>
    </ul>
    <div class="ps-scrollbar-x-rail" style="left: 0px; bottom: 3px;">
      <div class="ps-scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
    </div>
    <div class="ps-scrollbar-y-rail" style="top: 0px; height: 587px; right: 3px;">
      <div class="ps-scrollbar-y" tabindex="0" style="top: 0px; height: 81px;"></div>
    </div>
  </div>
</div>
